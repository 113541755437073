import React from 'react';
import { Field } from 'redux-form';
import {
    Toolbar, SaveButton, FormDataConsumer, SimpleForm, Create, Edit,
    ReferenceInput, List, Datagrid, EditButton, TextField, ReferenceField, NumberField
} from 'react-admin';
import ConfigInput from '../common/config-input'
import { IntegrationFilters } from '../common/filters'
import { Actions } from '../common/actions'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import DeleteButtonWithConfirmation from '../common/buttons/DeleteButtonWithConfirmation'
import InstanceForm from '../common/InstanceForm'

export const InstanceList = props => (
    <List {...props} actions={<Actions />} filterDefaultValues={{ development: false }} filters={<IntegrationFilters showEndpoint={true} showIntegration={true} showDevelopment={true} showExternalCompanyNumber={true} />} title="Connections" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField source="integrationId" reference="integrations" linkType={false}><TextField source="name" /></ReferenceField>
            <NumberField source="externalCompanyNumber" />
            <TextField source="subdivisionId" />
            <ReferenceField source="companyId" reference="companies" linkType={false}><TextField source="companyName" /></ReferenceField>
            <ReferenceField source="endpointId" reference="endpoints" linkType={false}><TextField source="endpointName" /></ReferenceField>
<EditButton />
        </Datagrid>
    </List>
);

const HiddenInput = ({ source }) => (
    <Field component="input" type="hidden" name={source} />
);

const WebhookAddress = ({...props}) => {
   let value = window.location.origin + "/api/Scheduling/hook?instanceId=" + props.record.integrationInstanceId + "&moduleId=" + props.record.integrationModuleId + "&moduleConfigurationId=" + props.record.integrationModuleConfigurationId;
   return (<span>{value}</span>);
}

const LandingForm = withRouter(({ staticContext, location, permissions, ...props }) => {
    var parsedHash = queryString.parse(location.search)
    var properties = parsedHash && parsedHash.properties && JSON.parse(parsedHash.properties)
    var authorizationCode = parsedHash.AuthorizationCode;
    if (authorizationCode) {
        properties = { Fortnox: { AuthorizationCode: authorizationCode } }
    }

    return (
        <SimpleForm {...props} redirect={() => `/connection-created`} defaultValue={{
            companyId: permissions.companyId,
            endpointId: permissions.endpointId,
            externalCompanyNumber: permissions.externalCompanyNumber,
            properties: properties
        }}>
            <HiddenInput source="integrationId" />
            <HiddenInput source="externalCompanyNumber" />
            <HiddenInput source="subdivisionId" />
            <HiddenInput source="companyId" />
            <HiddenInput source="endpointId" />
            {/* Hacky way of loading integrations :) */}
            <ReferenceInput source="integrationId" reference="integrations">
                <HiddenInput source="name" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <ConfigInput referenceSchema="configurationSchema"
                        reference="integrationconfigurations"
                        systemId={formData.systemId}
                        integrationId={formData.integrationId}
                        source="properties" context={{ permissions, companyId: formData.companyId, ...rest }} />
                }
            </FormDataConsumer>
        </SimpleForm>
    )
})

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButtonWithConfirmation />
    </Toolbar>
);

export const InstanceEdit = props => (
    <Edit title="Edit connection" {...props}>
        <InstanceForm LandingForm={LandingForm} CustomToolbar={CustomToolbar} WebhookAddress={WebhookAddress} permissions={props.permissions} isEditing={true} />
    </Edit>
);

export const InstanceCreate = props => (
    <Create title="Create connection" {...props}>
        <InstanceForm LandingForm={LandingForm} CustomToolbar={CustomToolbar} WebhookAddress={WebhookAddress}  permissions={props.permissions} />
    </Create>
);
