import React from 'react';
import ErrorFilterForm from '../common/error-filters/ErrorFilterForm.js';
import { Field } from 'redux-form';
import {
    FormDataConsumer, SimpleForm, Create, Edit, ReferenceInput, List,
    Datagrid, EditButton, TextField, ReferenceField, BooleanField
} from 'react-admin';
import ConfigInput from '../common/config-input'
import { ErrorFilterFilters } from '../common/error-filters/filters.js'
import { Actions } from '../common/actions'
import queryString from 'query-string'
import { withRouter } from 'react-router'

export const ErrorFilterList = props => (
    <List {...props} actions={<Actions />} filterDefaultValues={{ development: false }} filters={<ErrorFilterFilters showIntegration={true} showId={true} showMessage={true} />} title="Error filters" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <BooleanField source="isErrorAllowed" className="is-error-allowed overflow-elipsies" />
            <ReferenceField source="integrationId" reference="integrations" linkType={false}><TextField source="name" /></ReferenceField>
            <TextField source="resultMessage" className="filter-result-message overflow-elipsies" />
            <TextField source="filterExceptionRegex" className="filter-exception-regex overflow-elipsies" />
            <EditButton />
        </Datagrid>
    </List>
);

const HiddenInput = ({ source }) => (
    <Field component="input" type="hidden" name={source} />
);

const LandingForm = withRouter(({ staticContext, location, permissions, ...props }) => {
    var parsedHash = queryString.parse(location.search)
    var properties = parsedHash && parsedHash.properties && JSON.parse(parsedHash.properties)
    var authorizationCode = parsedHash.AuthorizationCode;
    if (authorizationCode) {
        properties = { Fortnox: { AuthorizationCode: authorizationCode } }
    }

    return (
        <SimpleForm {...props} redirect={() => `/connection-created`} defaultValue={{
            properties: properties
        }}>
            <HiddenInput source="integrationId" />
            {/* Hacky way of loading integrations :) */}
            <ReferenceInput source="integrationId" reference="integrations">
                <HiddenInput source="name" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <ConfigInput referenceSchema="configurationSchema"
                        reference="integrationconfigurations"
                        systemId={formData.systemId}
                        integrationId={formData.integrationId}
                        source="properties" context={{ permissions, companyId: formData.companyId, ...rest }} />
                }
            </FormDataConsumer>
        </SimpleForm>
    )
})

export const ErrorFilterEdit = props => (
    <Edit title="Edit error filter" {...props}>
        <ErrorFilterForm permissions={props.permissions} LandingForm={LandingForm} isEditing={true} />
    </Edit>
);

export const ErrorFilterCreate = props => (
    <Create title="Create error filter" {...props}>
        <ErrorFilterForm permissions={props.permissions} LandingForm={LandingForm} />
    </Create>
);