import React from 'react';
import { fetchUtils } from 'react-admin';
import { statusTypes } from './common/enums/statusTypes';
import { entityTypes } from './common/enums/entityTypes';
import { periodicityTypes } from './common/enums/periodicityTypes';
import { dataTypes } from './common/enums/dataTypes';
import portalDecorator from './common/data-provider/portal-data-provider-decorator';
import jsonServerProvider from './common/data-provider/data-provider';
import authProviderFactory from './common/auth-provider'
import englishMessages from 'ra-language-english';
import AdminWrapper from './common/AdminWrapper';

// domain translations
const domainMessages = {
  en: {
    enums: {
      periodicityTypes,
      statusTypes,
      dataTypes,
      entityTypes
    }
  }
}

const messages = {
  en: { ...englishMessages, ...domainMessages.en },
};
const i18nProvider = locale => messages[locale];

let serviceUrl = ".entrecloud.se";
if (window.location.hostname.includes("staging") || window.location.hostname.includes("testing") || window.location.hostname == 'localhost') {
    serviceUrl = ".staging.entrecloud.se";
}
let authProvider = authProviderFactory('https://auth' + serviceUrl);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

//To test API localy set baseApiUrl to localhost
//window.baseApiUrl = 'https://integrations.staging.entrecloud.se/api'
window.baseApiUrl = window.location.hostname == 'localhost' ? 'http://localhost:5010/api' : '/api'

let dataProvider = portalDecorator('https://admin' + serviceUrl + '/api', httpClient, jsonServerProvider(window.baseApiUrl, httpClient));

const App = () => (
    <AdminWrapper
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        dataProvider={dataProvider}
    />
);

export default App;
