import React from 'react';
import {
    Filter, DateInput, List, Datagrid, TextField, ReferenceField
} from 'react-admin';
import { Actions } from '../common/actions'

const ErrorReportFilters = ({ ...props }) => (
    <Filter {...props}>
        <DateInput label="Filter from" source="filterFrom" alwaysOn allowEmpty />
    </Filter>
);



export const ErrorReportList = props => (
    <List {...props} actions={<Actions />} filterDefaultValues={{ development: false }} filters={<ErrorReportFilters />} title="Error report" exporter={false} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="integrationInstanceId" />
            <ReferenceField source="companyId" reference="companies" linkType={false}><TextField source="companyName" /></ReferenceField>
            <ReferenceField source="integrationId" reference="integrations" linkType={false}><TextField source="name" /></ReferenceField>
            <TextField source="errorCount" />
        </Datagrid>
    </List>
);
